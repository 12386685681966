<template>
  <div class="box">
    <div class="box-body p-0" v-if="ready">
      <div class="container">
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close position-absolute"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
        <div class="row">
          <div class="col-md-4 border-right py-4 pl-3 pr-3">
            <h5 class="font-weight-bold">
              {{ province.name_th }}
            </h5>
            <div class="mb-3">
              {{ topic.title }}
            </div>
            <score
              :score="currentScore"
              class="ml-2"
              v-if="currentScore"
            ></score>
            <div class="form-group editor mt-4">
              <label class="font-weight-bold">หมายเหตุ</label>
              <textarea v-model="note" rows="5" class="form-control"></textarea>
            </div>
          </div>
          <div class="col-md-8 py-4 px-3">
            <h5 class="font-weight-bold">Major defect</h5>
            <div class="d-flex flex-column mb-3">
              <p-check
                class="p-svg p-curve p-smooth my-2"
                v-for="item in major_defect"
                v-bind:key="item.key"
                v-model="currentAnswer[item.key]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="svg svg-icon"
                  slot="extra"
                  fill="red"
                >
                  <g data-name="Layer 2">
                    <g data-name="checkmark">
                      <rect width="24" height="24" opacity="0" />
                      <path
                        d="M9.86 18a1 1 0 0 1-.73-.32l-4.86-5.17a1 1 0 1 1 1.46-1.37l4.12 4.39 8.41-9.2a1 1 0 1 1 1.48 1.34l-9.14 10a1 1 0 0 1-.73.33z"
                      />
                    </g>
                  </g>
                </svg>
                {{ item.title }}
              </p-check>
            </div>

            <h5 class="font-weight-bold">Minor defect</h5>
            <div class="d-flex flex-column mb-3">
              <p-check
                class="p-svg p-curve p-smooth my-2"
                v-for="item in minor_defect"
                v-bind:key="item.key"
                v-model="currentAnswer[item.key]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="svg svg-icon"
                  slot="extra"
                  fill="red"
                >
                  <g data-name="Layer 2">
                    <g data-name="checkmark">
                      <rect width="24" height="24" opacity="0" />
                      <path
                        d="M9.86 18a1 1 0 0 1-.73-.32l-4.86-5.17a1 1 0 1 1 1.46-1.37l4.12 4.39 8.41-9.2a1 1 0 1 1 1.48 1.34l-9.14 10a1 1 0 0 1-.73.33z"
                      />
                    </g>
                  </g>
                </svg>
                {{ item.title }}
              </p-check>
            </div>
            <button
              v-promise-btn
              @click="submitAnswer"
              class="btn btn-action float-right"
            >
              ยืนยันการประเมิน
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="box-body" v-if="!ready">
      <vue-element-loading
        :active="!ready"
        spinner="line-scale"
        color="#ED110E"
        background-color="transparent"
        class="box-body"
      />
    </div>
  </div>
</template>

<script>
import Score from '@/components/Score.vue';
import { find, orderBy, merge, transform } from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'PlanEvaluation',

  components: {
    Score,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    province: {
      type: Object,
      required: true,
    },
    topic: {
      type: Object,
      required: true,
    },
    major_defect: {
      type: Array,
      required: true,
    },
    minor_defect: {
      type: Array,
      required: true,
    },
    reportEvaluation: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      currentAnswer:
        (this.reportEvaluation || []).defect_answer || this.emptyAnswer(),
      note: (this.reportEvaluation || []).note,
      ready: false,
    };
  },

  watch: {},

  created() {
    this.fetchReportEvaluationAnswer({
      topic: this.topic,
      province: this.province,
    })
      .then((answer) => {
        this.currentAnswer = (answer || []).defect_answer || this.emptyAnswer();
        this.ready = true;
      })
      .catch((error) => {
        console.error(error);
        this.$toasted.global.display_error({
          message: error,
        });
      });
  },

  computed: {
    currentScore() {
      const result = find(
        orderBy(this.topic.evaluate, 'score', 'desc'),
        (item) => {
          const mapObject = this.major_defect.concat(this.minor_defect);
          const reg = new RegExp(mapObject.map((o) => o['key']).join('|'), 'g');
          const expression = item.formula.replace(reg, (matched) => {
            return this.currentAnswer[matched] ? 'true' : 'false';
          });
          return eval(expression);
        }
      );
      return (result || []).score;
    },
  },

  methods: {
    ...mapActions('evaluation', ['fetchReportEvaluationAnswer', 'evaluate']),
    emptyAnswer() {
      return merge(
        transform(
          this.major_defect,
          (result, value) => {
            result[value.key] = false;
          },
          {}
        ),
        transform(
          this.minor_defect,
          (result, value) => {
            result[value.key] = false;
          },
          {}
        )
      );
    },
    submitAnswer() {
      this.$emit('onEvaluated', this.province);
      return this.evaluate({
        topic: this.topic,
        province: this.province,
        defect_answer: this.currentAnswer,
        note: this.note,
      })
        .then((reportEvaluation) => {
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },

  destroyed() {},
};
</script>

<style lang="scss">
.pretty {
  margin-right: 0;
  white-space: normal;
  &.p-svg {
    .state .svg {
      width: calc(1rem + 2px);
      height: calc(1rem + 2px);
      top: 0.25rem;
    }
  }
  .state label {
    text-indent: 0;
    display: inline-block;
    padding-left: 1.5rem;
    line-height: 1.3rem;
  }
  .state {
    label::after,
    label::before {
      width: calc(1rem + 2px);
      height: calc(1rem + 2px);
      top: 0.25rem;
    }
  }
}
.close {
  top: 0.5rem;
  right: 1.5rem;
  z-index: 1000;
}
</style>

<template>
  <section class="container-fluid">
    <div class="row mt-3 mb-5">
      <div class="d-none d-md-block col-md-3">
        <div class="border w-100">
          <map-svg
            class="w-100"
            :selectedProvinces="[]"
            ref="mapSVG"
            @onRegionClick="selectProvinceByMap"
          />
        </div>
      </div>
      <div class="col-12 col-md-9">
        <div class="box box-responsive" v-if="ready">
          <div class="box-body pl-1">
            <div class="d-flex align-items-center">
              <span class="text-red font-weight-bold mx-3"
              >เลือกหัวข้อที่จะประเมิน</span
              >
              <select
                name="topic"
                v-model="selectedIndex"
                class="form-control w-50"
              >
                <option
                  v-for="(item, index) in topics"
                  :key="item.id"
                  v-bind:value="index"
                >
                  {{ item.title }}
                </option>
              </select>
            </div>
            <div class="mt-4">
              <div class="input-group">
                <input
                  class="form-control border-right-0 border"
                  type="text"
                  placeholder="ค้นหาจากชื่อจังหวัด"
                  v-model="search"
                />
                <span class="input-group-append">
                  <div class="input-group-text bg-transparent">
                    <eva-icon name="search-outline"></eva-icon>
                  </div>
                </span>
              </div>
            </div>
            <table class="table table-borderless less-padding">
              <thead class="header">
              <tr
                is="sorting-header"
                :headers="headers"
                @onSorting="sorting"
              ></tr>
              </thead>
              <tbody class="body">
              <tr
                v-for="province in filteredProvinces"
                v-bind:key="province.id"
              >
                <td class="d-flex">
                  <div>{{ province.name_th }} &nbsp;</div>
                  <router-link
                    v-if="canEvaluate(province.status)"
                    :to="{
                        name: 'pso.energy_report',
                        params: {
                          year: currentNationalPlan.year,
                          iso3166_2: province.iso3166_2,
                        },
                      }"
                    class=""
                    target="_blank"
                  >
                    [รายงาน]
                  </router-link>
                </td>
                <td :class="statusClass(province.status)">
                  {{ statusDisplay(province.status) }}
                </td>
                <td class="text-light-ipepp">
                  {{ (province.submitted_at || '-') | thai_date }}
                </td>
                <td class="text-light-ipepp">
                  {{ (province.evaluated_at || '-') | thai_date }}
                </td>
                <td>
                  <score
                    v-if="province.score"
                    :score="province.score"
                  ></score>
                </td>
                <td>
                  <div class="w-100 d-flex flex-nowrap">
                    <button
                      v-if="canEvaluate(province.status)"
                      @click="viewPlan(province)"
                      class="btn btn-outline-red font-weight-bold text-small px-2"
                    >
                      มองในมุมมอง สพจ.
                    </button>
                    <button
                      v-if="canEvaluate(province.status)"
                      @click="showEvaluate(province)"
                      class="ml-1 btn btn-action font-weight-bold text-small px-2"
                    >
                      ประเมิน
                    </button>
                    <button
                      v-if="isEvaluated(province.status)"
                      @click="showSummary(province)"
                      class="ml-1 btn btn-action font-weight-bold text-small px-2"
                    >
                      ดูผลรวม
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <vue-element-loading
          :active="!ready"
          spinner="spinner"
          color="#ED110E"
          background-color="transparent"
        />
        <modals-container @onEvaluated="evaluated"></modals-container>
      </div>
    </div>
  </section>
</template>

<script>
import SortingHeader from '@/components/SortingHeader.vue';
import PlanEvaluation from '@/components/PlanEvaluation.vue';
import EvaluationSummary from '@/components/EvaluationSummary.vue';
import Score from '@/components/Score.vue';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import {reduce, orderBy, filter, find} from 'lodash';
import nationalPlanChangedListener from '../../mixins/nationalPlanChangedListener';
import MapSvg from '@/components/MapSvg.vue';
import variables from '@/assets/variables.js';

export default {
  name: 'Evaluation',

  mixins: [nationalPlanChangedListener],

  components: {
    SortingHeader,
    Score,
    MapSvg,
  },

  data() {
    return {
      topicResult: {
        executive_summary: {
          score: 3,
          answer: {
            'Mj-1': true,
            'Mj-2': true,
            'Mj-3': false,
            'Mn-1': true,
          },
        },
      },
      headers: [
        {title: 'จังหวัด', key: 'name_th'},
        {title: 'สถานะ', key: 'status'},
        {title: 'ส่งเมื่อ', key: 'submitted_at'},
        {title: 'ตรวจเมื่อ', key: 'evaluated_at'},
        {title: 'คะแนนที่ได้', key: 'score'},
        {title: null, key: null},
      ],
      sorted: {
        key: null,
        order: 'asc',
      },
      search: '',
      ready: false,
    };
  },

  watch: {
    topicSelectedIndex(newVal, oldVal) {
      this.ready = false;
      this.fetchEvaluations().catch((error) => {
        console.error(error);
        this.$toasted.global.display_error({
          message: error,
        });
      });
    },
  },

  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },

  computed: {
    ...mapState(['provinces']),
    ...mapState('evaluation', ['topicSelectedIndex', 'topics']),
    ...mapGetters('evaluation', [
      'currentTopic',
      'getReport',
      'getReportEvaluation',
      'getReportEvaluationScore',
    ]),
    ...mapGetters(['currentNationalPlan']),
    selectedIndex: {
      set(index) {
        this.setTopicSelectedIndex(index);
      },
      get() {
        return this.topicSelectedIndex;
      },
    },
    filteredProvinces() {
      let filtered = this.mappedProvinces;
      if (this.search != null && this.search != '') {
        filtered = filtered.filter((province) => {
          return (
            province.name_th
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          );
        });
      }
      return this.sorted.key
        ? orderBy(filtered, [this.sorted.key], [this.sorted.order])
        : filtered;
    },
    mappedProvinces() {
      return this.provinces.map((province) => {
        return {
          ...province,
          status: this.status(province),
          submitted_at: (this.getReport(province) || []).submitted_at,
          evaluated_at: (this.getReportEvaluation(province) || []).evaluated_at,
          score: this.getReportEvaluationScore(province),
        };
      });
    },
  },
  methods: {
    ...mapActions('auth', ['setImpersonate', 'setImpersonatingFrom']),
    ...mapActions('evaluation', ['fetchTopics', 'fetchTopicReportEvaluations']),
    ...mapMutations('evaluation', ['setTopicSelectedIndex']),
    fetchData() {
      this.ready = false;
      this.fetchTopics(this.currentNationalPlan)
        .then(() => {
          this.setTopicSelectedIndex(0);
          return this.fetchEvaluations();
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
    fetchEvaluations() {
      this.ready = false;
      return this.fetchTopicReportEvaluations(this.currentTopic).then(() => {
        this.setProvinceStatusOnMap();
        this.ready = true;
      });
    },
    setProvinceStatusOnMap() {
      if (this.$refs.mapSVG) {
        this.$refs.mapSVG.setProvincesColor(
          this.mappedProvinces,
          variables.colors.red
        );
        this.$refs.mapSVG.setProvincesColor(
          filter(this.mappedProvinces, {status: 2}),
          variables.colors['dark-yellow']
        );
        this.$refs.mapSVG.setProvincesColor(
          filter(this.mappedProvinces, {status: 3}),
          variables.colors.green
        );
      }
    },
    currentNationalPlanChanged() {
      this.fetchData();
    },
    sorting(sorted) {
      this.sorted = sorted;
    },
    status(province) {
      const report = this.getReport(province);
      if (!report) {
        return 1;
      }
      const reportEvaluation = this.getReportEvaluation(province);
      if (
        !reportEvaluation ||
        (reportEvaluation && !reportEvaluation.evaluated_at)
      ) {
        return 2;
      }
      return 3;
    },
    statusClass(status) {
      switch (status) {
        case 1:
          return 'text-red';
        case 2:
          return 'text-yellow';
        default:
          return 'text-green';
      }
    },
    statusDisplay(status) {
      switch (status) {
        case 1:
          return 'ยังไม่ส่ง';
        case 2:
          return 'รอประเมิน';
        default:
          return 'ประเมินแล้ว';
      }
    },
    canEvaluate(status) {
      return status == 2 || status == 3;
    },
    isEvaluated(status) {
      return status == 3;
    },
    showEvaluate(province) {
      this.$modal.show(
        PlanEvaluation,
        {
          name: 'plan_evaluation',
          province: province,
          topic: this.currentTopic,
          major_defect: this.currentTopic.defect.major_defect,
          minor_defect: this.currentTopic.defect.minor_defect,
          reportEvaluation: this.getReportEvaluation(province),
        },
        {
          name: 'plan_evaluation',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '65%',
          transition: 'fade',
        }
      );
    },
    evaluated(province) {
      this.$refs.mapSVG.setProvincesColor([province], variables.colors.green);
      // this.ready = false;
      // return this.fetchTopicReportEvaluations(this.currentTopic).then(() => {
      //   this.ready = true;
      // });
    },
    showSummary(province) {
      this.$modal.show(
        EvaluationSummary,
        {
          name: 'evaluation_summary',
          province: province,
        },
        {
          name: 'evaluation_summary',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    selectProvinceByMap(iso3166_2) {
      const province = find(this.mappedProvinces, {iso3166_2: iso3166_2});
      if (!province) {
        return;
      }
      if (this.canEvaluate(province.status)) {
        this.showEvaluate(province);
      } else if (this.isEvaluated(province.status)) {
        this.showSummary(province);
      }
    },
    viewPlan(province) {
      window.axios
        .get('/api/impersonate/' + province.id)
        .then((response) => {
          this.setImpersonate(true);
          this.setImpersonatingFrom('evalution');
          window.location.href = response.data.redirect;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
